import React from 'react';
import '../App.css'; // Make sure to create this CSS file for styling

function Description() {
  return (
    <section className="description">
      <div className="description-container">
        <div className="description-left">
          <h2>МЫ ВОПЛОЩАЕМ МЫСЛИ В ПРОСТРАНСТВО</h2>
        </div>
        <div className="description-right">
          <p>
            В СМпроект мы убеждены: каждая идея заслуживает того, чтобы стать реальностью. Наша команда создаёт архитектурные решения, которые соединяют функциональность, красоту и внимание к деталям.
          </p>
          <p>
            Мы проектируем здания, интерьеры и экстерьеры, отражающие ваш стиль и потребности. Наш подход основан на гармонии с окружающей средой и стремлении к долговечности, чтобы ваши пространства оставались актуальными на долгие годы.
          </p>
          <p>
            Давайте вместе создадим пространство, которое будет вдохновлять, радовать и работать для вас. СМпроект — там, где идеи обретают форму.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Description;
