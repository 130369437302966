import React from 'react';
import './Components.css';  

function Services() {
  return (
    <div className="services">
      <h1 className="services-heading">Наши Услуги</h1>
      <p className="services-description">
        Наши идеи отвечают текучей, динамичной природе социального развития, ориентированы на концепции мест для жизни, истории для общего наследия, пространств для работы, достопримечательностей для осмотра и центров для обучения.
      </p>
      <div className="services-content">
        <img src="/images/architecture.jpg" alt="Architecture" className="services-image" />
        <ul className="services-list">
          <li className="service-item"><span className="service-item-number">01</span>Предпроектный анализ</li>
          <li className="service-item"><span className="service-item-number">02</span>Концепция</li>
          <li className="service-item"><span className="service-item-number">03</span>Проектная документация</li>
          <li className="service-item"><span className="service-item-number">04</span>Рабочая документация</li>
          <li className="service-item"><span className="service-item-number">05</span>Интерьеры</li>
          <li className="service-item"><span className="service-item-number">06</span>Авторский надзор</li>
          <li className="service-item"><span className="service-item-number">07</span>Разработка АГР, АГО</li>
        </ul>
      </div>
      <a href="mailto:filimonov_1955@inbox.ru" className="contact-us-button">Свяжитесь с нами, для расчета стоимости проекта</a>
    </div>
  );
}

export default Services; 