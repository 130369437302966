import React, { useEffect, useState } from 'react';
import '../Pages.css';

function Experience() {
  const [years, setYears] = useState(0);
  const [projects, setProjects] = useState(0);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const experienceYears = currentYear - 1978;
    const projectCount = 230;

    const yearsInterval = setInterval(() => {
      setYears((prev) => {
        if (prev < experienceYears) return prev + 1;
        clearInterval(yearsInterval);
        return prev;
      });
    }, 100);

    const projectsInterval = setInterval(() => {
      setProjects((prev) => {
        if (prev < projectCount) return prev + 10;
        clearInterval(projectsInterval);
        return prev;
      });
    }, 100);

    return () => {
      clearInterval(yearsInterval);
      clearInterval(projectsInterval);
    };
  }, []);

  return (
    <div className="experience">
      <div className="experience-item">
        <div className="experience-number">{years}</div>
        <div className="experience-text">лет опыта</div>
      </div>
      <div className="experience-item">
        <div className="experience-number">{projects}</div>
        <div className="experience-text">выполненных проектов</div>
      </div>
    </div>
  );
}

export default Experience;
