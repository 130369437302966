import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import '../Pages.css'; // Ensure styles are applied from Pages.css

const projects = [
  { id: 1, name: 'Алтай', thumbnail: './images/altai/altai1.png', fullImage: './images/altai/altai1.png' },
  { id: 2, name: 'Территория фитнеса', thumbnail: './images/fitness/fitness1.PNG', fullImage: './images/fitness/fitness1.PNG' },
  { id: 3, name: 'Forest Club, Видное', thumbnail: './images/forestclub/forestclub1.jpg', fullImage: './images/forestclub/forestclub1.jpg' },
  { id: 4, name: '"Созвездие", Ивантеевка', thumbnail: './images/ivanteevka/ivanteevka2.jpg', fullImage: './images/ivanteevka/ivanteevka2.jpg' },
  { id: 5, name: 'Тоннели, Крым', thumbnail: './images/krym/krym3.png', fullImage: './images/krym/krym3.png' },
  { id: 6, name: 'АТЭС, Владивосток', thumbnail: './images/vladivostok/vlad1.jpg', fullImage: './images/vladivostok/vlad1.jpg' },
  { id: 7, name: 'Мытищи', thumbnail: './images/mytishi/HV 01-01.jpg', fullImage: './images/mytishi/HV 01-01.jpg' },
  { id: 8, name: 'Здание администрации, Королев', thumbnail: './images/korolev-admin/full_PZddwcJe.jpg', fullImage: './images/korolev-admin/full_PZddwcJe.jpg' },
  { id: 9, name: 'ТРЦ "Гагарин", Ивантеевка', thumbnail: './images/gagarin/full_O9RJWiJ1.jpg', fullImage: './images/gagarin/full_O9RJWiJ1.jpg' },
  { id: 10, name: 'Пристройка к ТЦ N1, Королев', thumbnail: './images/n1/full_otRQQlKf.jpg', fullImage: './images/n1/full_otRQQlKf.jpg' },
  { id: 11, name: 'Музей Космонавтики', thumbnail: './images/museo/full_kG92oTtn.jpg', fullImage: './images/museo/full_kG92oTtn.jpg' },
  
  // Add more projects as needed
];

function Projects() {
  return (
    <div className="projects">
      <h1>Наши Проекты</h1>
      <div className="projects-grid">
        {projects.map((project) => (
          <div key={project.id} className="project-item">
            <Link to={`/projects/${project.id}`} className="project-name-button">
              {project.name}
            </Link>
            <div className="project-image-container">
              <Link to={`/projects/${project.id}`}>
                <img src={project.thumbnail} alt={project.name} className="project-image" />
              </Link>
              <div className="overlay">
                <span className="view-project-text">Смотреть</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Projects; 