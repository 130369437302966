import React from 'react';

function Hero() {
  return (
    <section className="hero">
      <div className="hero-overlay"></div>
      <div className="company-name">
        <p>Архитектурное<br/>бюро<br/>СМ ПРОЕКТ</p>
      </div>
      
    </section>
  );
}

export default Hero;