import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Footer from './components/Footer';
import Portfolio from './components/Portfolio';
import Description from './components/Description';
import Latest from './components/Latest';
import Contact from './components/Contact';
import Services from './components/Services';
import ConfidentialPolicy from './components/ConfidentialPolicy';
import AboutUs from './components/AboutUs';
import CookieConsent from './components/CookieConsent';
import Projects from './components/Projects';
import Altai from './projects/Altai';
import Fitness from './projects/Fitness';
import ForestClub from './projects/ForestClub';
import InteriorDesigns from './components/InteriorDesigns';
import InteriorRad from './projects/Iterior-rad';
import Experience from './components/Experience';
import Ivanteevka from './projects/Ivanteevka';
import Krym from './projects/Krym';
import Vladivostok from './projects/Vladivostok';
import Mytishi from './projects/Mytishi';
import OfficeCabinet from './projects/OfficeCabinet';
import DiningArea from './projects/DiningArea';
import OfficeZone from './projects/OfficeZone';
import MeetingRoom from './projects/MeetingRoom';
import SportsZone from './projects/SportsZone';
import RelaxationZone from './projects/RelaxationZone';
import Bedroom from './projects/Bedroom';
import KorolevAdmin from './projects/KorolevAdmin';
import Gagarin from './projects/Gagarin';
import N1 from './projects/N1';
import Museo from './projects/Museo'; 
import './components/mobile.css';


function App() {
  return (
    <Router>
      <div className="App">
        <CookieConsent />
        <Navbar />
        <Routes>
          <Route path="/" element={
            <>
              <Navbar />
              <Hero />
              <Portfolio />
              <Description />
              <Experience />
              <Latest />
              <Contact />
            </>
          } />
          <Route path="/services" element={<><Services /><Contact /></>} />
          <Route path="/confidential-policy" element={<><ConfidentialPolicy /><Contact /></>} />
          <Route path="/about-us" element={<><AboutUs /><Contact /></>} />
          <Route path="/projects" element={<><Projects /><Contact /></>} />
          <Route path="/projects/1" element={<><Altai /><Contact /></>} />
          <Route path="/projects/2" element={<><Fitness /><Contact /></>} />
          <Route path="/projects/3" element={<><ForestClub /><Contact /></>} />
          <Route path="/interior-designs" element={<><InteriorDesigns /><Contact /></>} />
          <Route path="/interior-designs/1" element={<><InteriorRad /><Contact /></>} />
          <Route path="/projects/4" element={<><Ivanteevka /><Contact /></>} />
          <Route path="/projects/5" element={<><Krym /><Contact /></>} />
          <Route path="/projects/6" element={<><Vladivostok /><Contact /></>} />
          <Route path="/projects/7" element={<><Mytishi /><Contact /></>} />
          <Route path="/interior-designs/4" element={<><OfficeCabinet /><Contact /></>} />
          <Route path="/interior-designs/5" element={<><DiningArea /><Contact /></>} />
          <Route path="/interior-designs/6" element={<><OfficeZone /><Contact /></>} />
          <Route path="/interior-designs/7" element={<><MeetingRoom /><Contact /></>} />
          <Route path="/interior-designs/8" element={<><SportsZone /><Contact /></>} />
          <Route path="/interior-designs/9" element={<><RelaxationZone /><Contact /></>} />
          <Route path="/interior-designs/10" element={<><Bedroom /><Contact /></>} />
          <Route path="/projects/8" element={<><KorolevAdmin /><Contact /></>} />
          <Route path="/projects/9" element={<><Gagarin /><Contact /></>} />
          <Route path="/projects/10" element={<><N1 /><Contact /></>} />
          <Route path="/projects/11" element={<><Museo /><Contact /></>} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;