import React from 'react';
import '../App.css'; // Make sure to create this CSS file for styling

const Portfolio = () => {
    return (
        <section className="portfolio">
            <h2 className="portfolio-heading">Чем мы занимаемся?</h2>
            <div className="portfolio-grid">
                <div className="portfolio-item large">
                    <img src="./images/architecture.jpg" alt="Project 1" />
                </div>
                {/* <div className="portfolio-item museo">
                    <img src="./images/museo-cosm.jpg" alt="Project 12" />
                </div> */}
                <div className="architecture-card">
                        <h3 className="large-heading">Архитектура</h3>
                        <p>Проекты, в которых каждая деталь продумана до совершенства. Мы создаем уникальные здания, объединяющие функциональность и эстетику.</p>
                </div>
                <div className="portfolio-item ex">
                    <img src="./images/altai/altai4.png" alt="Project 4" />
                </div>
                <div className="portfolio-item exterior">
                    <img src="./images/exterior-design.png" alt="Project 3" />
                </div>
                <div className="exterior-design-card">
                        <h3 className="large-heading">Дизайн экстерьера</h3>
                        <p>Экстерьеры, которые гармонично вписываются в окружающую среду. Стильный подход к каждому элементу вашего пространства.</p>
                </div>
                <div className="portfolio-item interior">
                    <img src="./images/interior-design.jpg" alt="Project 4" />
                </div>
                <div className="interior-design-card">
                        <h3 className="large-heading">Дизайн интерьера</h3>
                        <p>Интерьеры, отражающие вашу индивидуальность. Эргономика и красота, создающие уют в каждом уголке.</p>
                </div>
            </div>
        </section>
    );
};

export default Portfolio;
