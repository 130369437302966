import React from 'react';
import '../Pages.css';  


function ConfidentialPolicy() {
  return (
    <div className="confidential-policy">
      <h1>Политика конфиденциальности</h1>
      <p>
        Настоящая Политика конфиденциальности описывает, как мы собираем, используем и защищаем вашу личную информацию. Мы стремимся обеспечить безопасность ваших данных и соблюдаем все применимые законы о защите данных.
      </p>
      <h2>Сбор информации</h2>
      <p>
        Мы можем собирать следующую информацию:
        <ul>
          <li>Имя и контактные данные, включая адрес электронной почты</li>
          <li>Демографическую информацию, такую как почтовый индекс, предпочтения и интересы</li>
          <li>Другую информацию, относящуюся к опросам и предложениям клиентов</li>
        </ul>
      </p>
      <h2>Использование информации</h2>
      <p>
        Мы используем собранную информацию для:
        <ul>
          <li>Улучшения наших продуктов и услуг</li>
          <li>Отправки рекламных писем о новых продуктах, специальных предложениях или другой информации, которая, по нашему мнению, может быть вам интересна</li>
          <li>Связи с вами для проведения исследований рынка</li>
        </ul>
      </p>
      <h2>Защита информации</h2>
      <p>
        Мы обязуемся обеспечить безопасность вашей информации. Для предотвращения несанкционированного доступа или раскрытия мы внедрили соответствующие физические, электронные и управленческие процедуры для защиты и обеспечения безопасности информации, которую мы собираем в Интернете.
      </p>
      <h2>Контроль вашей личной информации</h2>
      <p>
        Вы можете ограничить сбор или использование вашей личной информации следующими способами:
        <ul>
          <li>Когда вас просят заполнить форму на сайте, ищите поле, которое вы можете отметить, чтобы указать, что вы не хотите, чтобы информация использовалась кем-либо для прямого маркетинга</li>
          <li>Если вы ранее согласились с нами на использование вашей личной информации для целей прямого маркетинга, вы можете изменить свое мнение в любое время, написав нам или отправив электронное письмо</li>
        </ul>
      </p>
      <p>
        Мы не будем продавать, распространять или сдавать в аренду вашу личную информацию третьим лицам, если у нас нет вашего разрешения или если это не требуется по закону.
      </p>
      <p>
        Если у вас есть какие-либо вопросы о нашей Политике конфиденциальности, пожалуйста, свяжитесь с нами.
      </p>
    </div>
  );
}

export default ConfidentialPolicy; 