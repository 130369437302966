import React, { useState, useEffect } from 'react';
import '../CookieConsent.css'; // Ensure you create this CSS file for styling

function CookieConsent() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="cookie-consent">
      <p>Мы используем cookies для улучшения вашего опыта на нашем сайте. Продолжая использовать сайт, вы соглашаетесь с нашей политикой использования cookies.</p>
      <button onClick={handleAccept}>Я согласен</button>
    </div>
  );
}

export default CookieConsent; 