import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const isMainPage = location.pathname === '/';
  const menuRef = useRef(null);
  const hamburgerRef = useRef(null);

  const toggleMenu = (event) => {
    event.stopPropagation();
    console.log('Toggle menu clicked');
    setMenuOpen(!menuOpen);
  };

  const handleClickOutside = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      hamburgerRef.current &&
      !hamburgerRef.current.contains(event.target)
    ) {
      console.log('Clicked outside menu and hamburger');
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <nav className={`navbar ${isMainPage ? 'navbar-main' : 'navbar-other'}`}>
      <div className="logo">
        <Link to="/">
          <img src="/images/logo-bn.png" alt="СМпроект Logo" className="logo-image" />
        </Link>
      </div>
      <button className="hamburger" onClick={toggleMenu} ref={hamburgerRef}>
        <span className="hamburger-icon"></span>
      </button>
      <ul className={`menu ${menuOpen ? 'menu-open' : ''}`} ref={menuRef}>
        <li><Link to="/services" onClick={() => setMenuOpen(false)}>УСЛУГИ</Link></li>
        <li><Link to="/projects" onClick={() => setMenuOpen(false)}>ПРОЕКТЫ</Link></li>
        <li><Link to="/interior-designs" onClick={() => setMenuOpen(false)}>ИНТЕРЬЕРЫ</Link></li>
        <li><Link to="/about-us" onClick={() => setMenuOpen(false)}>О НАС</Link></li>
      </ul>
    </nav>
  );
}

export default Navbar;
