import React from 'react';
import './Components.css';

function AboutUs() {
  return (
    <div className="about-us">
      <h1 className="team-heading">О нас</h1>
      <p className="team-description">
      Добро пожаловать в СМпроект! Мы — команда профессионалов, специализирующихся на создании уникальных архитектурных решений. Наша миссия — воплощать ваши идеи в реальность, создавая пространства, которые вдохновляют и радуют. 
      </p>
      <p className="team-description"> 
      Наша работа основана на дизайне: мы создаем новое и сохраняем старое. Мы подходим к проектам со свежими идеями и открытым сознанием, создавая креативные и устойчивые пространства. Наш энтузиазм к современности уравновешивается глубоким уважением к истории, что повышает ценность за счет соответствующего и деликатного дизайна.
      </p>
      <div className="ceo">
        <img src="/images/img_2260.jpg" alt="Евгений Филимонов" className="ceo-image" />
        <p className="ceo-description">
          <span className="ceo-name">Евгений Филимонов</span><br />
          <span className="ceo-title">Генеральный директор</span><br />
          Евгений Филимонов – основатель и руководитель архитектурной фирмы СМПРОЕКТ, занимающейся проектированием зданий и сооружений, превращая их в значимые места, чтобы сделать города лучше.<br /> <a href="mailto:filimonov_1955@inbox.ru" className="contact-us-button">Связаться с Евгением</a>
        </p>
      </div>
    </div>
  );
}

export default AboutUs; 