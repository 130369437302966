import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css'; // Make sure to create this CSS file for styling

function Latest() {
  return (
    <section className="latest">
      <h2 className="latest-heading">НЕДАВНИЕ ПРОЕКТЫ</h2>
      <p className="latest-description">
      В этом разделе вы найдете наши последние работы — от современных архитектурных решений до стильного дизайна интерьеров и экстерьеров. 
        <span className="desktop-only"> Мы гордимся каждым реализованным проектом, ведь за ними стоят уникальные идеи и истории. Каждая деталь продумана, чтобы создать гармоничные, функциональные и вдохновляющие пространства.</span>
      </p>
      <div className="latest-container">
        <div className="latest-item">
          <Link to="/projects" className="view-more">
            <img src="./images/altai/altai2.png" alt="Latest Project 1" />
          </Link>
        </div>
        <div className="latest-item">
          <Link to="/interior-designs" className="view-more">
            <img src="./images/latest-project2.png" alt="Latest Project 2" />
          </Link>
        </div>
        <div className="latest-item">
          <Link to="/projects" className="view-more">
            <img src="./images/vladivostok/vlad2.jpg" alt="Latest Project 3" />
          </Link>
        </div>
        <div className="latest-item">
          <Link to="/interior-designs" className="view-more">
            <img src="./images/krym/krym3.png" alt="Latest Project 4" />
          </Link>
        </div>
        <div className="latest-item">
          <Link to="/projects" className="view-more">
            <img src="./images/korolev-admin/full_nquBqAIX.jpg" alt="Latest Project 5" />
          </Link>
        </div>
        <div className="latest-item">
          <Link to="/interior-designs" className="view-more">
            <img src="./images/interior3/кафе В2.png" alt="Latest Project 6" />
          </Link>
        </div>
        <div className="latest-item">
          <Link to="/projects" className="view-more">
            <img src="./images/museo/full_h4BFMwF0.jpg" alt="Latest Project 7" />
          </Link>
        </div>
        <div className="latest-item">
          <Link to="/interior-designs" className="view-more">
            <img src="./images/interior4/OPEN_SPACE2.png" alt="Latest Project 8" />
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Latest;
