import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './projects.css'; // Ensure styles are applied from projects.css

const projectImages = [
  { id: 1, thumbnail: '../images/altai/altai1.png', fullImage: '../images/altai/altai1.png' },
  { id: 2, thumbnail: '../images/altai/altai2.png', fullImage: '../images/altai/altai2.png' },
  { id: 3, thumbnail: '../images/altai/altai3.png', fullImage: '../images/altai/altai3.png' },
  { id: 4, thumbnail: '../images/altai/altai4.png', fullImage: '../images/altai/altai4.png' },
  { id: 5, thumbnail: '../images/altai/altai5.png', fullImage: '../images/altai/altai5.png' },
  { id: 6, thumbnail: '../images/altai/altai6.png', fullImage: '../images/altai/altai6.png' },
  { id: 7, thumbnail: '../images/altai/altai7.png', fullImage: '../images/altai/altai7.png' },
  { id: 8, thumbnail: '../images/altai/altai8.png', fullImage: '../images/altai/altai8.png' },
  // Add more images as needed
];

function Altai() {
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/projects');
  };

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
  };

  const handleClose = () => {
    setSelectedImageIndex(null);
  };

  const handlePrev = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : projectImages.length - 1));
  };

  const handleNext = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex < projectImages.length - 1 ? prevIndex + 1 : 0));
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (selectedImageIndex !== null) {
        if (event.key === 'ArrowLeft') {
          handlePrev();
        } else if (event.key === 'ArrowRight') {
          handleNext();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedImageIndex]);

  return (
    <div className="project-detail">
      <button className="back-button" onClick={handleBack}>НАЗАД ВО ВСЕ ПРОЕКТЫ</button>
      <h1>Проект Алтай</h1>
      <div className="project-detail-grid">
        {projectImages.map((image, index) => (
          <div key={image.id} className="project-detail-item">
            <img
              src={image.thumbnail}
              alt={`Project Image ${index + 1}`}
              onClick={() => handleImageClick(index)}
            />
          </div>
        ))}
      </div>

      <button className="back-button back-button-end" onClick={handleBack}>НАЗАД ВО ВСЕ ПРОЕКТЫ</button>

      {selectedImageIndex !== null && (
        <div className="project-popup" onClick={handleClose}>
          <div className="project-popup-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={handleClose}>X</button>
            <button className="nav-button left" onClick={handlePrev}>&lt;</button>
            <img src={projectImages[selectedImageIndex].fullImage} alt={`Project Image ${selectedImageIndex + 1}`} />
            <button className="nav-button right" onClick={handleNext}>&gt;</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Altai;