import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import '../Pages.css'; // Ensure styles are applied from Pages.css

const interiorDesigns = [
  { id: 1, name: 'Интерьер дома', thumbnail: './images/interior-rad/interior-rad11.jpg', fullImage: './images/interior-rad/interior-rad11.jpg' },
  { id: 4, name: 'Офисный кабинет', thumbnail: './images/interior2/CABINET1.png', fullImage: './images/interior2/CABINET1.png' },
  { id: 5, name: 'Обеденная зона', thumbnail: './images/interior3/КАФЕ_В3.png', fullImage: './images/interior3/КАФЕ_В3.png' },
  { id: 6, name: 'Опен-спейс', thumbnail: './images/interior4/OFIS.png', fullImage: './images/interior4/OFIS.png' },
  { id: 7, name: 'Переговорная', thumbnail: './images/interior5/БИЗНЕС_ПЕРЕГОВОРНАЯ.png', fullImage: './images/interior5/БИЗНЕС_ПЕРЕГОВОРНАЯ.png' },
  { id: 8, name: 'Спорт-зона', thumbnail: './images/interior6/SPORT.png', fullImage: './images/interior6/SPORT.png' },
  { id: 9, name: 'Зона отдыха', thumbnail: './images/interior7/ОТДЫХ_РЕЛАКСАЦИЯ.png', fullImage: './images/interior7/ОТДЫХ_РЕЛАКСАЦИЯ.png' },
  { id: 10, name: 'Спальня', thumbnail: './images/interior8/SITY_Unnamed Space-1.jpeg', fullImage: './images/interior8/SITY_Unnamed Space-1.jpeg' },
  // Add more interior designs as needed
];

function InteriorDesigns() {
  return (
    <div className="projects">
      <h1>Наши Интерьерные Дизайны</h1>
      <div className="projects-grid">
        {interiorDesigns.map((design) => (
          <div key={design.id} className="project-item">
            <Link to={`/interior-designs/${design.id}`} className="project-name-button">
              {design.name}
            </Link>
            <div className="project-image-container">
              <Link to={`/interior-designs/${design.id}`}>
                <img src={design.thumbnail} alt={design.name} className="project-image" />
              </Link>
              <div className="overlay">
                <span className="view-project-text">Смотреть</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default InteriorDesigns;
