import React from 'react';
import '../App.css'; // Ensure styles are applied from App.css

function Contact() {
  return (
    <section className="contact">
      <h2 className="contact-heading">КОНТАКТЫ</h2>
      <div className="contact-container">
        <div className="contact-left">
          <h3>АДРЕС</h3>
          <p>Турчанинов переулок, д 6 стр 2<br />Москва, Россия</p>
        </div>
        <div className="contact-right">
          <h3>КОНТАКТЫ</h3>
          <p>Телефон:  +7 (919) 728-01-11</p>
          <p>Email: filimonov_1955@inbox.ru</p>
        </div>
        <div className="contact-menu">
          <h3>МЕНЮ</h3>
          <ul>
            <li><a href="/services">УСЛУГИ</a></li>
            <li><a href="/projects">ПРОЕКТЫ</a></li>
            <li><a href="/interior-designs">ИНТЕРЬЕРЫ</a></li>
            <li><a href="/about-us">О НАС</a></li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Contact;
