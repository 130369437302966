import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="footer">
      <p>© 2025 | Архитектурная мастерская СМпроект | <Link to="/confidential-policy">Политика конфиденциальности</Link></p>
    </footer>
  );
}

export default Footer;